import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import axios from 'axios'; 
import './App.css';
import { Button, Card, CardContent, CardActions, Typography, Link } from '@mui/material';

const contractABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "defaultAdmin",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "baseURI_",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "AccessControlBadConfirmation",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "bytes32",
				"name": "neededRole",
				"type": "bytes32"
			}
		],
		"name": "AccessControlUnauthorizedAccount",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "ERC721IncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ERC721InsufficientApproval",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "approver",
				"type": "address"
			}
		],
		"name": "ERC721InvalidApprover",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "ERC721InvalidOperator",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "ERC721InvalidOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "receiver",
				"type": "address"
			}
		],
		"name": "ERC721InvalidReceiver",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "ERC721InvalidSender",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ERC721NonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_fromTokenId",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_toTokenId",
				"type": "uint256"
			}
		],
		"name": "BatchMetadataUpdate",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "MetadataUpdate",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "previousAdminRole",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "newAdminRole",
				"type": "bytes32"
			}
		],
		"name": "RoleAdminChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleGranted",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "sender",
				"type": "address"
			}
		],
		"name": "RoleRevoked",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "DEFAULT_ADMIN_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MINTER_ROLE",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "minter",
				"type": "address"
			}
		],
		"name": "addMinter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			}
		],
		"name": "getRoleAdmin",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "grantRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "hasRole",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "minter",
				"type": "address"
			}
		],
		"name": "removeMinter",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "callerConfirmation",
				"type": "address"
			}
		],
		"name": "renounceRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "role",
				"type": "bytes32"
			},
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "revokeRole",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			}
		],
		"name": "safeMint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];
const contractAddress = '0x0afb65B14380B1551427aF967d1d00D2aA1a729b';
const polygonMainnetChainId = '0x89'; // Polygon Mainnet Chain ID

function App() {
    const [web3, setWeb3] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [contract, setContract] = useState(null);
    const [imageURL, setImageURL] = useState('');
    const [loading, setLoading] = useState(false);
    const [tokenId, setTokenId] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [tokenURL, setTokenURL] = useState(''); 

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', (_chainId) => {
                window.location.reload();
            });
        }
    }, []);

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const web3Instance = new Web3(window.ethereum);
                const chainId = await web3Instance.eth.getChainId();
                if (chainId.toString(16) === polygonMainnetChainId) {
                    await web3Instance.eth.requestAccounts();
                    setWeb3(web3Instance);
                    setAccounts(await web3Instance.eth.getAccounts());
                    const contractInstance = new web3Instance.eth.Contract(contractABI, contractAddress);
                    setContract(contractInstance);
                    setIsConnected(true);
                } else {
                    await switchToPolygonMainnet();
                }
            } catch (error) {
                console.error('Failed to connect:', error);
                alert("Failed to connect. Please try again.");
            }
        } else {
            alert("Please install MetaMask to use this app.");
        }
    };

    const switchToPolygonMainnet = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: polygonMainnetChainId }],
            });
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccounts(accounts);
            const web3Instance = new Web3(window.ethereum);
            setWeb3(web3Instance);
            const contractInstance = new web3Instance.eth.Contract(contractABI, contractAddress);
            setContract(contractInstance);
            setIsConnected(true);
        } catch (switchError) {
            if (switchError.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: polygonMainnetChainId,
                            chainName: 'Polygon Mainnet',
                            nativeCurrency: {
                                name: 'MATIC',
                                symbol: 'MATIC',
                                decimals: 18
                            },
                            rpcUrls: ['https://polygon-rpc.com/'],
                            blockExplorerUrls: ['https://polygonscan.com/']
                        }]
                    });
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    setAccounts(accounts);
                    const web3Instance = new Web3(window.ethereum);
                    setWeb3(web3Instance);
                    const contractInstance = new web3Instance.eth.Contract(contractABI, contractAddress);
                    setContract(contractInstance);
                    setIsConnected(true);
                } catch (addError) {
                    console.error('Failed to add the Polygon Mainnet network:', addError);
                    setIsConnected(false);
                }
            } else {
                console.error('Failed to switch to the Polygon Mainnet network:', switchError);
                setIsConnected(false);
            }
        }
    };

    const checkPendingTransactions = async (web3, account) => {
        const pending = await web3.eth.getTransactionCount(account, 'pending');
        const latest = await web3.eth.getTransactionCount(account, 'latest');
        if (pending > latest) {
            console.warn('Pending transactions detected for account:', account);
            return true;
        }
        return false;
    };

	const mintNFT = async () => {
		if (contract && accounts.length > 0) {
			setLoading(true);
			try {
				const hasPending = await checkPendingTransactions(web3, accounts[0]);
				if (hasPending) {
					setLoading(false);
					return;
				}
	
				console.log("Starting mint process...");
				const nonce = await web3.eth.getTransactionCount(accounts[0], 'pending');
	
				// Fetch the current gas price from the network
				const gasPrice = await web3.eth.getGasPrice();
				const gasPriceWithBuffer = Math.round(Number(gasPrice) * 1.2); 
	
				const gasLimit = 400000; 
	
				const mintResult = await contract.methods.safeMint(accounts[0]).send({
					from: accounts[0],
					gas: gasLimit.toString(), 
					gasPrice: gasPriceWithBuffer.toString(), 
					nonce: nonce,
				});
	
				const tokenId = mintResult.events.Transfer.returnValues.tokenId;
				setTokenId(tokenId);
				console.log("Minted Token ID:", tokenId);
	
				const metadataURI = await contract.methods.tokenURI(tokenId).call();
				console.log("Metadata URI:", metadataURI);
	
				fetchMetadata(metadataURI);
	

				const tokenURL = `https://polygonscan.com/token/${contractAddress}?a=${tokenId}`;
				setTokenURL(tokenURL);
	
				setLoading(false); 
			} catch (error) {
				console.error('Error minting NFT:', error);
				setLoading(false);
			}
		} else {
			setLoading(false);
			console.log("Contract not loaded or no accounts available");
		}
	};
	
	

    const fetchMetadata = async (uri) => {
        try {
            const convertedURI = convertIpfsUrl(uri);
            const response = await axios.get(convertedURI);
            console.log("Converted URI:", convertedURI);
            console.log("Metadata Response:", response.data);
            const imageIpfsUrl = response.data.image; // Fetch the image URL from metadata
            const imageUrl = convertIpfsUrl(imageIpfsUrl); // Convert the IPFS URL to HTTP URL
            setImageURL(imageUrl); // Update the image URL
        } catch (error) {
            console.error('Error fetching NFT metadata:', error);
        }
    };

    const convertIpfsUrl = (ipfsUrl) => {
        if (ipfsUrl.startsWith("ipfs://")) {
            return `https://ipfs.io/ipfs/${ipfsUrl.split("ipfs://")[1]}`;
        }
        return ipfsUrl;
    };

    return (
        <div className="App">
            <header className="App-header">
                <div className="banner" style={{ width: '100%', textAlign: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}/BANNER.png`} alt="Nifty Dauphine by Doors Lab" style={{ width: '100%' }} />
                </div>
                <h1>CLAIM YOUR NIFTY CERTIFICATE</h1>
                <Card sx={{ maxWidth: 345, margin: "20px auto", padding: "20px", textAlign: 'center' }}>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Ready to mint your exclusive NFT?
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            This minting process is limited to students who graduate from the Paris Dauphine DeFi Certificate program.
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Connect your wallet to proceed.
                        </Typography>
                    </CardContent>
                    <CardActions style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {loading && (
                            <div className="loading-spinner"></div>
                        )}
                        {!loading && !imageURL && (
                            <>
                                {!isConnected ? (
                                    <Button variant="contained" color="primary" onClick={connectWallet}>
                                        Connect Wallet
                                    </Button>
                                ) : (
                                    <Button variant="contained" color="secondary" onClick={mintNFT}>
                                        Mint NFT
                                    </Button>
                                )}
                            </>
                        )}
                        {imageURL && (
                            <>
                                <img src={imageURL} alt="Minted NFT" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                <Typography variant="body2" color="text.primary" style={{ marginTop: '20px' }}>
                                    NFT minted successfully! <br />
                                    <Link href={tokenURL} target="_blank" rel="noopener noreferrer">
                                        View on Polygonscan
                                    </Link>
                                </Typography>
                            </>
                        )}
                    </CardActions>
                </Card>
				
                <div className="image-links" style={{ display: 'flex', justifyContent: 'center', padding: '10px', backgroundColor: '#1B1D3B', alignItems: 'center', margin: '10px 0' }}>
                    <a href="https://doors3.io" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                        <img src={`${process.env.PUBLIC_URL}/LogoDoors3.png`} alt="Doors Lab Logo" style={{ width: '80px', height: '80px', borderRadius: '8px' }} />
                    </a>
                    <a href="https://opensea.io/collection/niftydauphine-1" target="_blank" rel="noopener noreferrer" style={{ margin: '0 50px' }}>
                        <img src={`${process.env.PUBLIC_URL}/logoopensea.png`} alt="See the Collection" style={{ width: '80px', height: '80px', borderRadius: '8px' }} />
                    </a>
                    <a href="https://executive-education.dauphine.psl.eu/formations/certificat/finance-decentralisee#:~:text=Certificat,Finance%20d%C3%A9centralis%C3%A9e%20(DeFi)&text=La%20formation%20est%20centr%C3%A9e%20autour,'inscrit%20dans%20l'actualit%C3%A9." target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px' }}>
                        <img src={`${process.env.PUBLIC_URL}/dauph.png`} alt="Dauphine Logo" style={{ width: '80px', height: '80px', borderRadius: '8px' }} />
                    </a>
                </div>
            </header>
        </div>
    );
}

export default App;
