import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App'; 
import NFCArt from './NFCArt';



const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/nifty" />} />
        <Route path="/nifty" element={<App />} />
        <Route path="/nfcart" element={<NFCArt />} />
        
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);