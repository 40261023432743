import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import './NFCArt.css';

const NFCArt = ({ location }) => {
  const [result, setResult] = useState('');
  const [nfcData, setNfcData] = useState('');
  const [part1Hex, setPart1Hex] = useState('');
  const [nftAddress, setNftAddress] = useState('');
  const [polygonScanUrl, setPolygonScanUrl] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const part1Hex = params.get('data');
    if (part1Hex) {
      setPart1Hex(part1Hex);
      setNfcData(part1Hex);
      verifyArtwork(part1Hex);
    }
  }, []);

  const verifyArtwork = async (part1Hex) => {
    try {
       // Connect to the Polygon testnet
       const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc-amoy.polygon.technology/')); // Use a Polygon testnet provider
       const contractAddress = '0x1073D7504Eb228f100dfe89C243b796d893B70bA'; // Replace with your deployed contract address
       const contractABI = [
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "to",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "approve",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "inputs": [],
              "stateMutability": "nonpayable",
              "type": "constructor"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "sender",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  },
                  {
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  }
              ],
              "name": "ERC721IncorrectOwner",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "operator",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "ERC721InsufficientApproval",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "approver",
                      "type": "address"
                  }
              ],
              "name": "ERC721InvalidApprover",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "operator",
                      "type": "address"
                  }
              ],
              "name": "ERC721InvalidOperator",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  }
              ],
              "name": "ERC721InvalidOwner",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "receiver",
                      "type": "address"
                  }
              ],
              "name": "ERC721InvalidReceiver",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "sender",
                      "type": "address"
                  }
              ],
              "name": "ERC721InvalidSender",
              "type": "error"
          },
          {
              "inputs": [
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "ERC721NonexistentToken",
              "type": "error"
          },
          {
              "anonymous": false,
              "inputs": [
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  },
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "approved",
                      "type": "address"
                  },
                  {
                      "indexed": true,
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "Approval",
              "type": "event"
          },
          {
              "anonymous": false,
              "inputs": [
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  },
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "operator",
                      "type": "address"
                  },
                  {
                      "indexed": false,
                      "internalType": "bool",
                      "name": "approved",
                      "type": "bool"
                  }
              ],
              "name": "ApprovalForAll",
              "type": "event"
          },
          {
              "anonymous": false,
              "inputs": [
                  {
                      "indexed": false,
                      "internalType": "uint256",
                      "name": "_fromTokenId",
                      "type": "uint256"
                  },
                  {
                      "indexed": false,
                      "internalType": "uint256",
                      "name": "_toTokenId",
                      "type": "uint256"
                  }
              ],
              "name": "BatchMetadataUpdate",
              "type": "event"
          },
          {
              "anonymous": false,
              "inputs": [
                  {
                      "indexed": false,
                      "internalType": "uint256",
                      "name": "_tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "MetadataUpdate",
              "type": "event"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "recipient",
                      "type": "address"
                  },
                  {
                      "internalType": "string",
                      "name": "tokenURI",
                      "type": "string"
                  }
              ],
              "name": "mintNFT",
              "outputs": [
                  {
                      "internalType": "uint256",
                      "name": "",
                      "type": "uint256"
                  }
              ],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "from",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "to",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "safeTransferFrom",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "from",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "to",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  },
                  {
                      "internalType": "bytes",
                      "name": "data",
                      "type": "bytes"
                  }
              ],
              "name": "safeTransferFrom",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "operator",
                      "type": "address"
                  },
                  {
                      "internalType": "bool",
                      "name": "approved",
                      "type": "bool"
                  }
              ],
              "name": "setApprovalForAll",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "anonymous": false,
              "inputs": [
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "from",
                      "type": "address"
                  },
                  {
                      "indexed": true,
                      "internalType": "address",
                      "name": "to",
                      "type": "address"
                  },
                  {
                      "indexed": true,
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "Transfer",
              "type": "event"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "from",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "to",
                      "type": "address"
                  },
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "transferFrom",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  }
              ],
              "name": "balanceOf",
              "outputs": [
                  {
                      "internalType": "uint256",
                      "name": "",
                      "type": "uint256"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "getApproved",
              "outputs": [
                  {
                      "internalType": "address",
                      "name": "",
                      "type": "address"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "address",
                      "name": "owner",
                      "type": "address"
                  },
                  {
                      "internalType": "address",
                      "name": "operator",
                      "type": "address"
                  }
              ],
              "name": "isApprovedForAll",
              "outputs": [
                  {
                      "internalType": "bool",
                      "name": "",
                      "type": "bool"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [],
              "name": "name",
              "outputs": [
                  {
                      "internalType": "string",
                      "name": "",
                      "type": "string"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "ownerOf",
              "outputs": [
                  {
                      "internalType": "address",
                      "name": "",
                      "type": "address"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "bytes4",
                      "name": "interfaceId",
                      "type": "bytes4"
                  }
              ],
              "name": "supportsInterface",
              "outputs": [
                  {
                      "internalType": "bool",
                      "name": "",
                      "type": "bool"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [],
              "name": "symbol",
              "outputs": [
                  {
                      "internalType": "string",
                      "name": "",
                      "type": "string"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          },
          {
              "inputs": [
                  {
                      "internalType": "uint256",
                      "name": "tokenId",
                      "type": "uint256"
                  }
              ],
              "name": "tokenURI",
              "outputs": [
                  {
                      "internalType": "string",
                      "name": "",
                      "type": "string"
                  }
              ],
              "stateMutability": "view",
              "type": "function"
          }
  ]; // Replace with your contract's ABI
       const nftContract = new web3.eth.Contract(contractABI, contractAddress);
 
       // Token ID you want to verify
       const tokenId = 7; 


      const tokenCID = await nftContract.methods.tokenURI(tokenId).call();
      const tokenURI = `https://ipfs.io/ipfs/${tokenCID}`;
      console.log("Token URI:", tokenURI);

      const response = await fetch(tokenURI);
      const responseText = await response.text();
      console.log("Fetched Metadata:", responseText);

      let metadata;
      try {
        metadata = JSON.parse(responseText);
      } catch (jsonError) {
        console.error("JSON Parsing Error:", jsonError);
        setResult('Failed to parse JSON: ' + jsonError.message);
        return;
      }

      const part2Hex = metadata.part2;
      console.log("Part2 Hex:", part2Hex);

      const part1 = new Uint8Array(part1Hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
      const part2 = new Uint8Array(part2Hex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
      console.log("Part1 Decoded:", part1);
      console.log("Part2 Decoded:", part2);

      const encryptedInfo = new Uint8Array(part1.length + part2.length);
      encryptedInfo.set(part1);
      encryptedInfo.set(part2, part1.length);
      console.log("Encrypted Info:", encryptedInfo);

      const salt = await fetch('/salt.bin').then(res => res.arrayBuffer());
      const iv = await fetch('/iv.bin').then(res => res.arrayBuffer());
      const password = 'securepassword';

      const decryptedInfo = await decryptInformation(salt, iv, encryptedInfo, password);
      setNftAddress(contractAddress);
      setPolygonScanUrl(`https://amoy.polygonscan.com/token/${contractAddress}?a=${tokenId}`);
      setResult(`Decrypted Information: ${decryptedInfo}`);
    } catch (error) {
      console.error("Verification Error:", error);
      setResult('Failed to verify artwork: ' + error.message);
    }
  };

  const decryptInformation = async (salt, iv, encryptedInfo, password) => {
    try {
      const keyMaterial = await crypto.subtle.importKey(
        "raw",
        new TextEncoder().encode(password),
        { name: "PBKDF2" },
        false,
        ["deriveBits", "deriveKey"]
      );

      const key = await crypto.subtle.deriveKey(
        {
          name: "PBKDF2",
          salt: new Uint8Array(salt),
          iterations: 100000,
          hash: "SHA-256"
        },
        keyMaterial,
        { name: "AES-CBC", length: 256 },
        false,
        ["decrypt"]
      );

      console.log("Key:", key);

      const decrypted = await crypto.subtle.decrypt(
        { name: "AES-CBC", iv: new Uint8Array(iv) },
        key,
        encryptedInfo
      );

      console.log("Decrypted:", decrypted);

      return new TextDecoder().decode(decrypted);
    } catch (error) {
      console.error("Decryption Error:", error);
      throw new Error('Failed to decrypt information');
    }
  };

  return (
    <div className="container">
      <h1 className="title">NFC Art Authentication</h1>
      <div className="content">
        <p className="data">NFC Data: {nfcData}</p>
        {polygonScanUrl && (
          <p className="cert">
            Certificat NFT: <a href={polygonScanUrl} target="_blank" rel="noopener noreferrer">{nftAddress}</a>
          </p>
        )}
        <p className="result">Result: {result}</p>
      </div>
    </div>
  );
};

export default NFCArt;
